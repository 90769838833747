@font-face {
  font-family: 'Retro_Gaming';
  src: url('./Retro_Gaming.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*{
  font-family: 'Retro_Gaming';
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  /* color: #61dafb; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 
.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 100ms, transform 100ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 100ms, transform 100ms;
} 

input {
  outline: none;
  border:1px solid grey;
  border-radius: 10px;
  /* margin-left:  1rem; */
  background-image:none;
  background-color:white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;  
  padding: 1rem;
}

 
.fadevideo-enter {
  opacity: 0;
}
.fadevideo-enter-active {
  opacity: 1;
  transition: 1000ms;
}
.fadevideo-exit {
  opacity: 1;
}
.fadevideo-exit-active {
  opacity: 0;
  transition: 1000ms;
} 

.tac{
  text-align: center;
}